import { cn } from '@arcadehq/shared/helpers'
import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { testID } from 'src/utils/tests'
import colors from 'styles/colors'

import { LoaderType } from './loaders/type'
import Spinner from './Spinner'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode | string
  buttonType?: ButtonTypes
  buttonSize?: ButtonSizes
  className?: string
  loading?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  iconOnly?: ReactNode
}

export enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  neutral = 'neutral',
  ghost = 'ghost',
  danger = 'danger',
  text = 'text', // Deprecated... use ghost instead (might re-use it if ghost become an issue with padding)
  base = 'base', // Deprecated... use neutral instead
  light = 'light', // Deprecated... use neutral instead
}

export enum ButtonSizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export const Button = React.forwardRef(function Button(
  {
    children,
    className,
    disabled,
    buttonType = ButtonTypes.primary,
    buttonSize = ButtonSizes.md,
    loading = false,
    leftIcon,
    rightIcon,
    iconOnly,
    ...props
  }: IButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  if (disabled) buttonType = ButtonTypes.neutral

  const iconGap = cn({
    'pr-[6px]': buttonSize === ButtonSizes.sm,
    'pr-[8px]': buttonSize === ButtonSizes.md || buttonSize === ButtonSizes.lg,
    'pr-[10px]': buttonSize === ButtonSizes.xl,
  })

  return (
    <button
      {...testID('Button')}
      title={props.title}
      ref={ref}
      className={cn(
        'font-semibold relative',
        'rounded-lg',
        'transition-colors no-animation focus:outline-none',
        {
          'bg-primary-action border border-primary-border text-white shadow-btn hover:bg-primary-action-hover hover:border-primary-action active:bg-primary-action active:border-primary-border active:shadow-none':
            buttonType === ButtonTypes.primary,
          'bg-white border border-gray-200 text-gray-900 hover:bg-gray-50 hover:border-gray-200 active:bg-gray-100 active:border-gray-200':
            buttonType === ButtonTypes.secondary,
          'bg-gray-100 border border-gray-100 text-gray-900 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 active:border-gray-300':
            buttonType === ButtonTypes.base ||
            buttonType === ButtonTypes.light ||
            buttonType === ButtonTypes.neutral,
          'bg-transparent border border-transparent text-gray-900 hover:bg-gray-100 hover:border-gray-100 active:bg-gray-200 active:border-gray-200':
            buttonType === ButtonTypes.ghost,
          'bg-transparent text-gray-900 hover:bg-transparent hover:text-gray-900 link px-0':
            buttonType === ButtonTypes.text,
          'bg-critical-action border border-critical-border text-white shadow-btn hover:bg-critical-action-hover hover:border-critical-action active:bg-critical-action active:border-critical-border active:shadow-none':
            buttonType === ButtonTypes.danger,
          'bg-gray-100 border border-gray-100 cursor-not-allowed shadow-none hover:bg-gray-100 hover:border-gray-100 active:bg-gray-100 active:border-gray-100 text-gray-500':
            disabled,
          'h-8 px-2 text-xs [&_svg]:h-4 [&_svg]:w-4':
            buttonSize === ButtonSizes.sm,
          'h-9 px-4 text-sm [&_svg]:h-[18px] [&_svg]:w-[18px]':
            buttonSize === ButtonSizes.md,
          'h-12 px-4 text-base [&_svg]:h-6 [&_svg]:w-6':
            buttonSize === ButtonSizes.lg,
          'h-16 px-6 text-lg [&_svg]:h-6 [&_svg]:w-6':
            buttonSize === ButtonSizes.xl,
          'w-8 p-[8px]': iconOnly && buttonSize === ButtonSizes.sm,
          'w-9 p-[9px]': iconOnly && buttonSize === ButtonSizes.md,
          'w-12 p-[12px]': iconOnly && buttonSize === ButtonSizes.lg,
          'w-16 p-[20px]': iconOnly && buttonSize === ButtonSizes.xl,
        },
        className
      )}
      {...props}
      onClick={props.onClick && !loading ? props.onClick : undefined}
    >
      {iconOnly ? (
        <div className='flex items-center justify-center absolute inset-0'>
          {iconOnly}
        </div>
      ) : (
        <div className='flex items-center justify-center'>
          {leftIcon}
          {leftIcon && children && <div className={iconGap} />}
          {loading ? (
            <Spinner
              color={
                buttonType === ButtonTypes.primary
                  ? colors.white
                  : colors.gray[900]
              }
              secondaryColor={colors.gray[200]}
              size={20}
              type={LoaderType.Oval}
            />
          ) : typeof children === 'string' ? (
            <span>{children}</span>
          ) : (
            children
          )}
          {rightIcon && children && <div className={iconGap} />}
          {rightIcon}
        </div>
      )}
    </button>
  )
})
